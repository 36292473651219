<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div class="d-flex flex-column-fluid">
            <div class="container">
              <div v-if="order.id" class="card card-custom gutter-b col-lg-6 mx-auto p-10">
                <div class="card-header d-flex flex-column flex-sm-row border-0 p-0">
                  <div class="card-title font-weight-bolder">
                    <div class="card-label">
                      <h3 class="font-weight-bolder d-flex align-items-center">
                        {{ $t('checkout.order_number', {number: order.id}) }}
                        <small class="ml-3">
                          <span :class="getStatues(order.status_id).class">{{ $t('checkout.status.' + getStatues(order.status_id).name) }}</span>
                        </small>
                      </h3>
                      <div class="font-size-sm mt-2">{{ $t('checkout.created_at', {date: formatDate(order.created_at)}) }}</div>
                    </div>
                  </div>
                  <div class="card-toolbar d-flex flex-column align-items-start align-items-sm-end text-left text-sm-right">
                    <div class="text-muted">{{order.location.name}}</div>
                    <div class="text-muted">{{order.location.address}}</div>
                  </div>
                </div>

                <div class="card-body p-0" style="position: relative;">
                  <core-datatable :loaded="true" v-bind:columns.sync="columns" :items="order.products">
                    <template #product="{ item }">
                      {{item.name}}
                    </template>
                    <template #quantity="{ item }">
                      {{item.quantity}}
                    </template>
                    <template #cost="{ item }">
                      {{ formatPrice(item.cost, order.currency.symbol) }}
                    </template>
                  </core-datatable>

                  <div class="row">
                    <div class="col-md-7 align-self-end">
                      <div class="row">
                        <div class="col text-muted">{{ $t('checkout.duration') }}</div>
                      </div>
                      <div class="row">
                        <div class="col font-weight-bolder">{{ $t('checkout.duration_from_to', {from: formatDate(order.ts_start), to: formatDate(order.ts_end) }) }}</div>
                      </div>
                    </div>
                    <div class="col-md-5 mt-4 mt-md-0 justify-content-md-end text-md-right">
                      <div class="row mt-1">
                        <div class="col text-muted">{{ $t('checkout.total') }} <span class="text-body">{{ formatPrice(order.cost, order.currency.symbol) }}</span></div>
                      </div>
                      <div class="row mt-1 text-muted">
                        <div class="col text-muted">{{ $t('checkout.discount') }} <span class="text-body">{{ formatPrice(order.discount, order.currency.symbol) }}</span></div>
                      </div>
                      <div class="row mt-2">
                        <div class="col font-weight-bolder">{{ $t('checkout.total_to_pay') }} <span>{{ formatPrice(order.total_cost, order.currency.symbol) }}</span></div>
                      </div>
                    </div>
                  </div>
                  <template v-if="$route.query.result != 0">
                    <hr class="my-5">
                    <div class="row mt-5">
                      <div class="col font-weight-bolder">{{ $t('checkout.payment_method') }}:</div>
                    </div>
                    <div class="row mt-3">
                      <div class="col">
                        <div class="btn-group mr-2" v-if="!identity.id && order.location.is_online_payment_enabled">
                          <button type="button" class="btn btn-white mb-1 border"
                                  :class="checkout.payment_method == 1 ? 'border-success show' : 'border-secondary'"
                                  @click="selectPaymentMethod(1)">
                            <font-awesome-icon class="icon-md text-success mr-2" icon="credit-card"></font-awesome-icon>
                            {{ $t('checkout.new_card') }}
                          </button>
                        </div>
                        <div class="btn-group mr-2" v-if="identity.id && order.location.is_online_payment_enabled">
                          <button type="button" class="btn btn-white mb-1 dropdown-toggle border" data-toggle="dropdown"
                                  aria-haspopup="true" aria-expanded="false"
                                  :class="checkout.payment_method == 1 ? 'border-success show' : 'border-secondary'"
                                  @click="selectPaymentMethod(1)">
                            <font-awesome-icon class="icon-md text-success mr-2" icon="credit-card"></font-awesome-icon>
                            <span v-if="checkout.pay_card.id">{{ $t('checkout.pay_card_title', {card: checkout.pay_card.card_masked}) }}</span>
                            <span v-else>{{ $t('checkout.new_card') }}</span>
                          </button>
                          <div class="dropdown-menu" :class="checkout.payment_method == 1 && show_dropdown ? 'show' : ''">
                            <a class="dropdown-item" href="#" v-for="saved_card in saved_cards" :key="saved_card.id"
                               @click="setPayCard(saved_card)">{{ saved_card.card_masked }}</a>
                            <a class="dropdown-item" href="#" @click="setPayCard({})">{{ $t('checkout.new_card') }}</a>
                          </div>
                        </div>
                        <div v-if="identity.id && order.location.is_company_invoice_payment_enabled && (companies_invoice.length > 0 || order.location.is_invoice_payment_enabled)" class="btn-group mr-2">
                          <button type="button" class="btn btn-white mb-1 border dropdown-toggle" data-toggle="dropdown"
                                  aria-haspopup="true" aria-expanded="false"
                                  :class="checkout.payment_method == 2 ? 'border-success show' : 'border-secondary'"
                                  @click="selectPaymentMethod(2)">
                            <font-awesome-icon class="icon-md text-success mr-2" icon="file-invoice-dollar"></font-awesome-icon>
                            <span v-if="checkout.invoice_company.id">{{ $t('checkout.pay_invoice_title', {invoice: checkout.invoice_company.name}) }}</span>
                            <span v-else>{{ $t('checkout.invoice') }}</span>
                          </button>
                          <div class="dropdown-menu" :class="checkout.payment_method == 2 && show_dropdown ? 'show' : ''">
                            <a class="dropdown-item" href="#" v-for="company_invoice in companies_invoice" :key="company_invoice.id"
                               @click="setInvoiceEntry(company_invoice)">{{ company_invoice.name }}</a>
                            <a v-if="order.location.is_invoice_payment_enabled"
                               class="dropdown-item" href="#" @click="setInvoiceEntry({})">{{ $t('checkout.individual') }}</a>
                          </div>
                        </div>
                        <div class="btn-group mr-2" v-if="identity.id && certificate_cnt >= order.total_cost">
                          <button type="button" class="btn btn-white mb-1 border"
                                  :class="checkout.payment_method == 3 ? 'border-success show' : 'border-secondary'"
                                  @click="selectPaymentMethod(3)">
                            <font-awesome-icon class="icon-md text-success mr-2" icon="certificate"></font-awesome-icon>
                            {{ $t('checkout.certificate') }}
                          </button>
                        </div>
                        <div class="btn-group mr-2" v-if="identity.id && (this.maxCoinsCompanyBalance >= order.total_cost || this.coinsUserBalance >= order.total_cost)">
                          <button type="button" class="btn btn-white mb-1 border dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            :class="checkout.payment_method == 9 ? 'border-success show' : 'border-secondary'"
                            @click="selectPaymentMethod(9)">
                            <font-awesome-icon class="icon-md text-success mr-2" icon="certificate"></font-awesome-icon>
                            <span v-if="checkout.invoice_company.id">Coins {{ checkout.invoice_company.name }}</span>
                            <span v-else>Coins</span>
                          </button>
                          <div class="dropdown-menu" :class="checkout.payment_method == 9 && show_dropdown ? 'show' : ''">
                            <a v-if="coinsUserBalance >= order.total_cost" class="dropdown-item" href="#" @click="setInvoiceEntry({})">{{ $t('checkout.individual') }}</a>
                            <a class="dropdown-item" href="#" v-for="company in companiesWithCoins" :key="company.id" @click="setInvoiceEntry(company)">{{ company.name }}</a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2" v-if="identity.id">
                      <div v-if="order.location.is_online_payment_enabled" class="col-12">
                        <div class="form-group form-check mb-2">
                          <input type="checkbox" class="form-check-input" value="1" v-model="checkout.save_card" id="remember_card"
                                 :disabled="checkout.payment_method != 1 || checkout.pay_card.id">
                          <label class="form-check-label text-muted" for="remember_card" style="padding-top: 2px">{{ $t('checkout.remember_card') }}</label>
                        </div>
                      </div>
                    </div>
                    <div v-if="!identity.id" class="row mt-2 mb-4">
                      <div class="col"><div v-html="$t('checkout.message-if-not-signet', {redirect_link: '/checkout/' + id})"></div></div>
                    </div>
                    <div v-if="order.policy && order.policy.url && !contract" class="row mt-2">
                      <div class="col-12">
                        <div class="form-group form-check mb-0">
                          <input type="checkbox" class="form-check-input" v-model="checkout.policy" id="policy">
                          <label for="policy" class="custom-link">
                            <a class="form-check-label text-muted" :href="order.policy.url" target="_blank">{{ $t('checkout.policy') }}</a>
                          </label>
                        </div>
                        <div v-if="isNoPolicyCheck" class="text-danger">{{ $t('checkout.policy_error_message') }}</div>
                      </div>
                    </div>
                    <div v-if="contract && contract.url" class="row mt-2">
                      <div class="col-12">
                        <div class="form-group form-check mb-0">
                          <input type="checkbox" class="form-check-input" v-model="checkout.contract" id="policy">
                          <label for="policy" class="custom-link">
                            <a class="form-check-label text-muted" :href="contract.url" target="_blank">{{ contract.name.replace(".pdf", "") }}</a>
                          </label>
                        </div>
                        <div v-if="isNoPolicyCheck" class="text-danger">{{ $t('checkout.policy_error_message') }}</div>
                      </div>
                    </div>
                    <div class="row mt-5">
                      <div class="col">
                        <button v-if="checkout.payment_method == 1 && order.location.is_online_payment_enabled" class="btn btn-success"
                                :disabled="loading"
                                @click="payPayment" :class="loading ? 'spinner spinner-darker-success spinner-left' : ''">{{ $t('checkout.pay') }}</button>
                        <button v-if="checkout.payment_method == 2" class="btn btn-success"
                                :disabled="loading"
                                @click="invoicePayment" :class="loading ? 'spinner spinner-darker-success spinner-left' : ''">{{ $t('checkout.issue') }}</button>
                        <button v-if="checkout.payment_method == 3" class="btn btn-success"
                                :disabled="loading"
                                @click="certificatePayment" :class="loading ? 'spinner spinner-darker-success spinner-left' : ''">{{ $t('checkout.pay') }}</button>
                        <button v-if="checkout.payment_method == 9" class="btn btn-success" :disabled="loading" @click="coinsPayment" :class="loading ? 'spinner spinner-darker-success spinner-left' : ''">{{ $t('checkout.pay') }}</button>
                        <span v-if="order.location.is_online_payment_enabled" class="font-size-sm">
                        <font-awesome-icon class="icon text-success ml-5 mr-1" icon="lock"></font-awesome-icon> {{ $t('checkout.protected_by_encryption') }}
                      </span>
                      </div>
                    </div>
                  </template>

                  <div class="row mt-5">
                    <div class="col text-muted">
                      {{ $t('checkout.cancel_the_order_before') }} {{ formatDate(order.can_canceled_until) }}
                    </div>
                  </div>

<!--                  <div v-if="order.policy && order.policy.url" class="row mt-2 mb-4">-->
<!--                    <div class="col fs-6"><small v-html="$t('checkout.message-about-policy', {policy_link: order.policy.url})"></small></div>-->
<!--                  </div>-->

                </div>
                <div v-if="payForm" v-html="payForm" style="display: none;" id="hidden-pay-form"></div>

                <b-modal v-model="dialogErrorModal" size="md" @ok="dialogErrorModal = false"
                         :title="$t('checkout.errorTitle')"
                         :no-fade="true"
                         :ok-only="true"
                         :cancel-title="$t('checkout.close')">
                  <div class="d-flex flex-column align-items-center">
                    <span v-if="checkout.message === true">
                      {{ $t('checkout.failed_unsupported') }}
                    </span>
                    <span v-else-if="checkout.message">
                      {{ checkout.message }}
                    </span>
                  </div>
                </b-modal>

                <b-modal v-model="dialogSuccessModal" size="md" ok-only @ok="dialogSuccessModal = false"
                         :title="$t('pay_result.title_modal')" :ok-title="$t('pay_result.close')">
                  <div v-if="$route.query.result == 0" class="d-flex flex-column align-items-center">
                    <div class="symbol symbol-50 symbol-light-success mb-4 flex-shrink-0">
                      <div class="symbol-label">
                        <font-awesome-icon icon="check" class="text-success icon-lg"/>
                      </div>
                    </div>
                    <div class="font-weight-bolder">{{ $t('pay_result.title_ok') }}</div>
                    <div>{{ $t('pay_result.body_ok') }}</div>
                    <a class="mt-5" :href="getGoToOrdersLink()">
                      <button class="btn btn-secondary">{{ $t('pay_result.to_orders') }}</button>
                    </a>
                  </div>
                  <div v-else class="d-flex flex-column align-items-center">
                    <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
                      <div class="symbol-label">
                        <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
                      </div>
                    </div>
                    <div class="font-weight-bolder">{{ $t('pay_result.title_fail') }}</div>
                    <div>{{ $t('pay_result.body_fail') }}</div>
                  </div>
                </b-modal>

                <b-modal @hidden="invoiceSuccessHide" v-model="dialogInvoiceSuccessModal" size="md" @ok="dialogInvoiceSuccessModal = false"
                         :title="$t('checkout.invoice_modal_title')"
                         :no-fade="true"
                         :ok-only="true"
                         :cancel-title="$t('checkout.close')">
                  <div v-html="$t('checkout.invoice_modal_body')"></div>
                </b-modal>

                <b-modal @hidden="depositSuccessHide" v-model="dialogDepositSuccessModal" size="md" @ok="dialogDepositSuccessModal = false"
                         :title="$t('checkout.deposit_modal_title')"
                         :no-fade="true"
                         :ok-only="true"
                         :cancel-title="$t('checkout.close')">
                  <div v-html="$t('checkout.deposit_modal_body')"></div>
                </b-modal>
              </div>
              <div v-else-if="loading" class="card card-custom gutter-b card-stretch col-lg-6 mx-auto">

                <div class="card-body" style="position: relative;">
                  <div class="row mt-10">
                    <div class="col-7 align-self-end">
                      <div class="row">
                        <div class="col text-muted"><PuSkeleton/></div>
                      </div>
                      <div class="row">
                        <div class="col"><PuSkeleton/></div>
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="row">
                        <div class="col text-muted"><PuSkeleton/> <span class="text-black-50"><PuSkeleton/></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="card card-custom gutter-b card-stretch col-lg-6 mx-auto">
                <p class="text-center m-5">{{ $t('checkout.not_found') }}</p>
              </div>
              <div class="col-lg-6 mx-auto d-flex align-items-center justify-content-between">
                <div class="text-left">
                  <a v-if="$i18n.locale == 'en'" href="#" class="text-success" @click="switchLocale('ru')">Переключить на русский</a>
                  <a v-if="$i18n.locale == 'ru'" href="#" class="text-success" @click="switchLocale('en')">Switch to English</a>
                </div>
<!--                <div class="text-right"><span>{{ $t('checkout.powered')}}</span></div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
  .dropdown-toggle.btn:after {
    content: '▾' !important;
    font-family: inherit !important;
  }

  @media (min-width: 991.98px) {
    .table {
      min-width: unset !important;
    }

    .table tr td:last-child {
      white-space: nowrap;
    }
  }
</style>


<script>
import {mapGetters, mapState, mapActions} from 'vuex'
let cookie = require('vue-cookie');
import api from "@/plugins/api";
import moment from "moment";

const PAYMENT_METHOD_CARD = 2
const PAYMENT_METHOD_INVOICE = 3
const PAYMENT_METHOD_CERTIFICATE = 8
const PAYMENT_METHOD_COINS = 9

export default {
  props: {
    id: {
      type: [String, Number],
      default: false
    },
  },
  data: () => {
    return {
      checkout: {
        order_id: 0,
        save_card: 0,
        policy: 0,
        contract: 0,
        back_ref: null,
        payment_method: 1,
        pay_card: {},
        invoice_company: {},
        message: false
      },
      loading: false,
      isNoPolicyCheck: false,
      contract: null,
      certificate_cnt: 0,
      dialogErrorModal: false,
      dialogSuccessModal: false,
      dialogInvoiceSuccessModal: false,
      dialogDepositSuccessModal: false,
      show_dropdown: false,
      payForm: '',
      saved_cards: [],
      companies_invoice: [],
      order: {},
      statuses: [
        { id: 4, name: 'Waiting payment', class: 'label label-lg label-inline label-light-warning' },
        { id: 5, name: 'Paid', class: 'label label-lg label-inline label-light-primary' },
        { id: 6, name: 'Canceled', class: 'label label-lg label-inline label-light-danger' },
        { id: 7, name: 'Credit', class: 'label label-lg label-inline label-light-info' },
        { id: 10, name: 'Finished', class: 'label label-lg label-inline label-light-success' },
      ],
      columns: [
        { text: 'checkout.columns.index', value: '#', key: 'index', sort: false },
        { text: 'checkout.columns.product', slot: 'product', key: 'product', sort: false },
        { text: 'checkout.columns.quantity', slot: 'quantity', key: 'quantity', sort: false },
        { text: 'checkout.columns.cost', slot: 'cost', key: 'cost', sort: false },
      ],
    }
  },
  computed: {
    ...mapGetters({
      identity: 'user/identity'
    }),
    coinsCompaniesList() {

    },
    maxCoinsCompanyBalance() { //баланс баллов компании, на которую оформляется заказ
      let coins = this.$store.state.dashboard.coins.company.coins ? this.$store.state.dashboard.coins.company.coins : []
      const companyMap = new Map()
      coins.forEach(item => companyMap.set(item.company_id, (companyMap.get(item.company_id) || 0) + item.remain))
      coins = 0
      companyMap.forEach(balance => {
        if(balance > coins) coins = balance
      })
      return coins
    },
    coinsUserBalance() { //баланс физического лица, на которого оформляется заказ
      const coins = this.$store.state.dashboard.coins.user.coins ? this.$store.state.dashboard.coins.user.coins : []
      return coins.reduce((amount, item) => amount + item.remain, 0)
    },
    companiesWithCoins() {
      const coins = this.$store.state.dashboard.coins.company.coins ? this.$store.state.dashboard.coins.company.coins : []
      return this.companies_invoice.filter(company => {
        return coins.reduce((amount, coin) => coin.company_id === company.id ? amount + coin.remain : amount, 0) >= this.order.total_cost
      })
    }
  },
  mounted() {
    let self = this
    this.getOrder()
    this.loadUserData()
    if (this.$route.query.result) {
      this.dialogSuccessModal = true;
    }
    this.$store.dispatch('dashboard/fetchCoins')
  },
  methods: {
    invoiceSuccessHide() {
      document.location.href = '/invoice'
    },
    depositSuccessHide() {
      document.location.href = '/order'
    },
    switchLocale(locale) {
      this.$i18n.locale = locale
      cookie.set("locale", locale, {expires: '1Y'});
      document.location.reload()
    },
    selectPaymentMethod(value) {
      this.show_dropdown = !this.show_dropdown
      this.checkout.payment_method = value
      this.loadUserData()
    },
    setPayCard(saved_card) {
      this.checkout.pay_card = saved_card
      this.show_dropdown = false
    },
    setInvoiceEntry(invoice_entry) {
      this.checkout.invoice_company = invoice_entry
      this.show_dropdown = false
    },
    getBackRef() {
      return window.__env.checkout_url.replace(/{guid}/i, this.$route.params.id);
    },
    getGoToOrdersLink() {
      let defaultLink = window.top.location.origin + '/order';
      if (!this.order || !this.order.products) {
        return defaultLink;
      }
      return this.order.products[0].product_type === 'meeting_room' && this.$route.query.roomId ?
          window.top.location.origin + '/services/room/' + this.$route.query.roomId + '?isOrderBooked=true' :
          defaultLink;
    },
    formatPrice(value, symbol) {
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' ' + symbol;
    },
    formatDate(value) {
      if (value) {
        if (typeof(value) == 'number') {
          return moment.unix(value).format('DD.MM.YYYY')
        }
        else {
          return moment(value).format('DD.MM.YYYY')
        }
      }
    },
    getStatues(statusId) {
      let statusLabel = { name: '', class: ''}
      this.statuses.forEach(function(item, i, arr) {
        if (item.id == statusId) {
          statusLabel = item
        }
      })
      return statusLabel;
    },
    redirectIfCompleted() {
      document.location.href = this.getBackRef() + '?result=0';
    },
    payPayment() {
      let self = this
      if ((!self.checkout.policy && !self.contract) || (!self.checkout.policy && !self.checkout.contract)) {
        self.isNoPolicyCheck = true;
      } else {
        self.loading = true;
        self.isNoPolicyCheck = false;
        if (self.checkout.pay_card.id) {
          api.post('/v2/payment/pay', {
            order_id: self.checkout.order_id,
            card_id: self.checkout.pay_card.id
          }).then(responce => {
            self.checkout.message = false
            self.loading = false
            self.redirectIfCompleted()
          }).catch(reason => {
            if (reason.response.data && reason.response.data.error.extra.length > 0) {
              self.checkout.message = reason.response.data.error.extra[0]
            }
            else if (reason.response.data && reason.response.data.error.length === 0 && reason.response.data.message) {
              self.checkout.message = this.$t('checkout.payment_error');
            }
            else {
              self.checkout.message = true
            }
            self.dialogErrorModal = true
            self.loading = false
          })
        }
        else {
          api.get('/v2/payment/form', {
            order_id: self.checkout.order_id,
            back_ref: self.checkout.back_ref,
            save_card: self.checkout.save_card,
          }).then(responce => {
            self.checkout.message = false
            self.payForm = responce.data ? responce.data.form : ''
            setTimeout(() => {
              const htmlElement = document.getElementById('hidden-pay-form');
              if (!htmlElement) {
                return;
              }
              const form = htmlElement.getElementsByTagName('form')
              const script = htmlElement.getElementsByTagName('script')
              if (script.length > 0) {
                const scriptCode = script[0].innerText;
                const newScript = document.createElement('script');
                const inlineScript = document.createTextNode(scriptCode);
                newScript.appendChild(inlineScript);
                document.getElementById('hidden-pay-form').appendChild(newScript);
              } else if (form.length > 0) {
                form[0].submit();
              }
              self.loading = false
            });
          }).catch(reason => {
            if (reason.response.data && reason.response.data.error.extra.length > 0) {
              self.checkout.message = reason.response.data.error.extra[0]
            }
            else if (reason.response.data && reason.response.data.error.length === 0 && reason.response.data.message) {
              self.checkout.message = this.$t('checkout.payment_error');
            }
            else {
              self.checkout.message = true
            }
            self.dialogErrorModal = true
            self.loading = false
          })
        }
      }
    },
    invoicePayment() {
      let self = this
      if ((!self.checkout.policy && !self.contract) || (!self.checkout.policy && !self.checkout.contract)) {
        self.isNoPolicyCheck = true;
      } else {
        self.loading = true;
        self.isNoPolicyCheck = false;
        api.put('/v2/orders/' + self.checkout.order_id + '/change-payment-method', {
          payment_id: PAYMENT_METHOD_INVOICE,
          company_id: self.checkout.invoice_company ? self.checkout.invoice_company.id : null
        }).then(responce => {
          self.checkout.message = false
          self.loading = false
          self.dialogInvoiceSuccessModal = true
        }).catch(reason => {
          if (reason.response.data && reason.response.data.error.extra[0]) {
            self.checkout.message = reason.response.data.error.extra[0]
          }
          else if (reason.response.data && reason.response.data.error) {
            self.checkout.message = reason.response.data.error.extra[Object.keys(reason.response.data.error.extra)[0]][0]
          }
          else {
            self.checkout.message = true
          }
          self.dialogErrorModal = true
          self.loading = false
        })
      }
    },

    certificatePayment() {
      let self = this
      if ((!self.checkout.policy && !self.contract) || (!self.checkout.policy && !self.checkout.contract)) {
        self.isNoPolicyCheck = true;
      } else {
        self.loading = true;
        self.isNoPolicyCheck = false;
        api.post('/v2/orders/'+self.checkout.order_id+'/pay-with-deposit', {
          user_id: self.identity.id,
          use_purse: 'user:' + self.identity.id
        }).then(responce => {
          self.checkout.message = false
          self.loading = false
          self.dialogDepositSuccessModal = true
        }).catch(reason => {
          if (reason.response.data) {
            self.checkout.message = reason.response.data.error.extra[0]
          }
          else {
            self.checkout.message = true
          }
          self.dialogErrorModal = true
          self.loading = false
        })
      }
    },

    coinsPayment() { //Оплата баллами лояльности (coins)
      if((!this.checkout.policy && !this.contract) || (!this.checkout.policy && !this.checkout.contract)) {
        this.isNoPolicyCheck = true
        return
      }
      this.loading = true
      this.isNoPolicyCheck = false
      const companyID = this.checkout.invoice_company ? this.checkout.invoice_company.id : null
      const data = { user_id: this.identity.id, use_purse: (companyID ? 'company:' + companyID : 'user:' + this.identity.id) }
      api.post('/v2/orders/' + this.checkout.order_id + '/pay-with-coins', data).then(response => {
        this.checkout.message = false
        this.loading = false
        this.dialogDepositSuccessModal = true
        if(companyID) this.$store.state.dashboard.coins.company.coins = null; else this.$store.state.dashboard.coins.user.coins = null
      }).catch(reason => {
        if(reason.response.data) this.checkout.message = reason.response.data.error.extra[0]
        else this.checkout.message = true
        this.dialogErrorModal = true
        this.loading = false
      })
    },

    getOrder() {
      let self = this
      self.loading = true
      api.get('/v2/orders/by-guid/' + self.id + '?expand=policy').then(responce => {
          self.order = responce.data ? responce.data : {}
          self.checkout.order_id = self.order ? self.order.id : null
          self.checkout.back_ref = this.getBackRef();
          self.$store.dispatch('tariffServices/GetInfo', responce.data.tariffs[0].id).then((res) => {
            if (res.contract) {
              self.contract = res.contract;
            }
          });
          self.loading = false
        }).catch(() => {
          self.loading = false
      })
    },
    getCards() {
      let self = this
      api.get('/v2/cards', {}).then(responce => {
          self.saved_cards = responce.data ? responce.data : {}
        })
    },
    getCompanies() {
      let self = this
      api.get('/v2/companies', {}).then(responce => {
          self.companies_invoice = responce.data ? responce.data : {}
        })
    },
    loadUserData() {
      if (this.identity.id) {
        let self = this
        this.getCards()
        this.getCompanies()

        let data = {expand: "rate,order", not_spent: false, sort: "id", state: "current"}
        // let data = {expand: "rate,order", not_spent: false, sort: "id"}
        api.get('/v2/packages', data)
            .then(responce => {
              let packages = responce.data
              let cnt_used = 0
              let cnt = 0
              if (packages.length > 0) {
                for (let i in packages) {
                  if (packages[i].rate.length > 0) {
                    for (let r in packages[i].rate) {
                      if (packages[i].rate[r].rate.type == 'certificate') {
                        cnt_used = cnt_used + packages[i].rate[r].cnt_used || 0
                        cnt = cnt + packages[i].rate[r].cnt || 0
                      }
                    }
                  }
                }
                self.certificate_cnt = cnt - cnt_used
              }
            }).catch(reason => {
          console.error('reason', reason)
        })
      }
    }
  },
  watch: {
    id: {
      handler(to, from) {
        this.getOrder()
      },
      deep: true,
    },
    'checkout.payment_method': {
      handler(to, from) {
        this.show_dropdown = true
      },
      deep: true,
    },
    'identity.id': {
      handler(to, from) {
        this.loadUserData()
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
  .custom-link {
    padding-top: 2px;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
</style>
