<template>
  <div ref="appTimeline">
    <v-touch class="app-timeline" v-on:panmove="onMouseMove($event)">
      <div class="timeline-balloon hide" :style="{ 'left': balloonLeft + 'px' }">
        <template v-if="$route.name !== 'Room'">
          <div class="timeline-balloon-arrow"></div>
          <div class="timeline-balloon-title">
            {{bookingTime}}, {{bookingDate}}
            <span v-if="is_valid_range">,
              <template v-if="checkIsTypeExist">
                0 {{bookingCurrency}}
                <span>
                  <b-button class="btn btn-icon btn-xs btn-circle bg-transparent border-0 w-auto h-auto mr-1 p-0" :id="'zero-payment-' + room_id">
                    <font-awesome-icon icon="question-circle" class="text-info icon-sm mr-3 mb-3"/>
                  </b-button>
                  <b-tooltip :target="'zero-payment-' + room_id" triggers="hover" placement="topright" :no-fade=true>
                    <small class="text-muted font-weight-bolder text-left"
                    >{{ $t('meeting_rooms.Zero_Payment') }}</small>
                  </b-tooltip>
                </span>
              </template>
              <template v-else>{{bookingPrice}} {{bookingCurrency}}</template>
            </span>
          </div>
          <div class="timeline-balloon-content">
          <span class="wrong" v-if="!is_valid_range">
            {{ $t('meeting_rooms.Not_Available') }}
          </span>
            <p class="info" v-if="min_booking_time_error">
              {{ $t('meeting_rooms.Minimal_Booking_Time', { time: min_booking_time }) }}
            </p>
            <p class="info" v-if="booking_step_error">
              {{ $t('meeting_rooms.Minimal_Booking_Step', { step: booking_step }) }}
            </p>

            <span v-if="is_valid_range">
            <router-link class="btn btn-block btn-success" :to="'/services/room/' + room_id + '?time=' + timestamp + '&from=' + rangePeriod.from + '&to=' + rangePeriod.to">
              <span class="text-capitalize text-white font-weight-bolder font-size-h6">{{ $t('meeting_rooms.Book_Now') }}</span>
            </router-link>
          </span>
          </div>
        </template>
      </div>

      <div class="timeline-arrow left" :class="start === 0 ? 'hide' : ''" v-on:click="moveTimeline(-1)"></div>
      <div class="timeline-arrow right" :class="start === limit ? 'hide' : ''" v-on:click="moveTimeline(1)"></div>

      <div class="timeline-container">
        <div class="timeline-scroll" v-on:scroll="onScroll($event)">
          <div class="timeline-content" :style="{ 'width': componentWidth + 'px' }">
            <div class="hours">
              <ul>
                <li v-for="i of times" :key="i.id" class="hour" :style="{ 'width': (100 / times.length) + '%' }">
                  {{ i }}:00
                </li>
              </ul>
            </div>
            <div class="steps" style="width: 100%">
              <div class="range hide" :style="{ 'left': rangeLeft + 'px', 'right': rangeRight + 'px', 'min-width': stepSize + 'px' }" @click.self="clickRange($event)">
                <v-touch tag="i" class="range-drag left" v-on:panstart="onMouseDown($event)" v-on:panend="onMouseUp($event)"></v-touch>
                <v-touch tag="i" class="range-drag right" v-on:panstart="onMouseDown($event)" v-on:panend="onMouseUp($event)"></v-touch>
              </div>
              <div class="background" :style="{ 'left': 0 + 'px', 'right': 0 + 'px' }"></div>
              <div class="booked2"
                v-for="b of booked_monolith"
                :key="b.id"
                :style="{ 'left': b.start * stepSize + 'px', 'right':  componentWidth - b.end * stepSize + 'px', 'min-width': stepSize + 'px' }"
              ></div>

              <ul>
                <v-touch
                  tag="span"
                  v-on:tap="addRange($event)"
                  v-on:panstart="addRange($event)"
                  v-on:panend="onMouseUp($event)"
                >
                  <li
                    v-for="date of periods"
                    :key="date.id"
                    class="step"
                    :class="[isBooked(date.ts + timestamp) ? 'booked': '', date.hour ? 'hours' : 'minutes']"
                    :style="{ 'width': stepSize + 'px' }"
                  ></li>
                </v-touch>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </v-touch>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Api from '@/plugins/api';
import 'hammer-timejs';
import moment from "moment";

import router from "vue-router";

export default {
  components: {},
  computed: {
    ...mapGetters( `roomsServices`, {
      loaded: `loaded`,
      roomPrice: `roomPrice`,
      freeRoomTime: `freeRoomTime`,
    }),
    ...mapGetters( `user`, {
      availableUserResources: `availableUserResources`,
    }),
    checkIsTypeExist() {
      let item = this.availableUserResources.filter(item => {
        if (item && item.resource_type === 'deposit' && item.available_value >= this.bookingPrice) {
          return item;
        } else if (item && item.resource_type === 'hours' && item.total_value >= (this.rangePeriod.to - this.rangePeriod.from)) {
          return item;
        }
      })
      return item.length > 0;
    },
  },
  props: [
    'roomData',
    'freeRoomTimeData',
    'timeStamp',
    'isRoom'
    // 'fromTimeStamp',
    // 'toTimeStamp'
    // 'bookedRoom',
  ],
  data() {
    return {
      seconds: null,
      booked_monolith: [],
      sub: [],
      start: 0,
      limit: 0,
      range_left: null,
      range_right: null,
      rangeTimeline: [0, 24], // Интервал времени на вывод;
      stepSize: 100, // Автоматически расчитанный размер блока;
      times: [],
      periods: [],
      rangeLeft: null,
      rangeRight: null,
      balloonLeft: 0,
      // user: User;
      rate: null,
      room_id: [],
      timestamp: 0, // Тут хранится текущая дата;
      step: 4, // На сколько участков бьем (по 30 минут или по 15);
      is_book: true,
      setStep: 0,
      selectRange: {},
      stepTime: 3600, // Количество секунд в шаге;
      booked:[], // Забронированные участки времени;
      step_timeline: 7,
      booked_cache: {},
      bookingTime: '',
      bookingPrice: 0,
      bookingDate: '',
      bookingCurrency: 'руб.',
      part_show: 3, // Количество частей для отображения;
      appNode: null,
      node: null,
      scrollContainer: null,
      range: null,
      control: null,
      balloon: null,
      componentWidth: 0,
      pageOffset: 0,
      moveByRange: false,
      time_show: null,
      range_from: 0,
      range_to: 0,
      is_valid_range: true,
      min_booking_time_int: null,
      booking_step_int: null,
      min_booking_time: null,
      booking_step: null,
      min_booking_time_error: null,
      booking_step_error: null,
      rangePeriod: {},
    }
  },
  mounted() {
    for (let i = this.rangeTimeline[0]; i < this.rangeTimeline[1]; i++) {
      this.times.push(i);
    }

    this.getTimes();
    this.appNode = this.$refs.appTimeline;
    this.node = this.appNode.querySelector('.timeline-container');
    this.componentWidth = this.node.offsetWidth * this.part_show;
    this.scrollContainer = this.appNode.querySelector('.timeline-scroll');
    this.range = this.appNode.querySelector('.range');
    this.range_right = this.range.querySelector('.range-drag.right');
    this.range_left = this.range.querySelector('.range-drag.left');
    this.balloon = this.appNode.querySelector('.timeline-balloon');
    this.update();
    this.setLimit();

    if (this.$route.name === 'Room') {
      this.rangePeriod = {
        from: +this.$route.query['from'],
        to: +this.$route.query['to']
      };
      this.createRange(+this.$route.query['time'], +this.$route.query['from'], +this.$route.query['to']);
      this.updateRange();
      this.updateBalloonPosition();

      const self = this;
      setTimeout(function () {
        self.sendDataAboutRange();
      }, 100);
    }

    // this.sub = this.$route.params.listen(params => {
    //   if (params['from'] && params['to']) {
    //     this.createRange(+params['from'], +params['to']);
    //     this.updateRange();
    //     this.updateBalloonPosition();

    //     const self = this;
    //     setTimeout(function () {
    //       self.sendDataAboutRange();
    //     }, 100);
    //   }
    // });

    this.booked = [];

    let items = (this.isRoom || this.freeRoomTimeData.length > 0 ? this.freeRoomTimeData : this.roomData['unavailable_time']) || [];
    this.booked = items.map(item => ({
      ...item,
      ts_start: (item['time_offset'] && item.ts_start) ? item.ts_start + item['time_offset'] : item.ts_start,
      ts_end: (item['time_offset'] && item.ts_end) ? item.ts_end + item['time_offset'] : item.ts_end,
    }));
    
    this.$nextTick(() => {
      setTimeout(() => {
        this.setTimestamp(this.timeStamp);
        this.startIn(this.step_timeline);
        this.bookedIn(this.booked);
        this.roomIn(this.roomData);
      }, 500)
    })

    // this.bookedIn(this.getMeetingRoomsBooked());
  },
  updated() {
    this.scrollContainer.scrollLeft = Math.round(this.start * this.stepSize * this.step);
  },
  methods: {
    createDetail(){
      this.$router.push({
        name: 'room',
        path: '/services/room',
        params: {
          id: this.room_id,
          time: this.timestamp,
          from: this.rangePeriod.from,
          to: this.rangePeriod.to,
        }
      })
    },

    moment: function () {
      return moment();
    },

    createRange: function (time, from, to) {
      const left = ((from - time) / 60) / 15;
      const right = ((to - time) / 60) / 15;
      this.rangeLeft = left * this.stepSize;
      this.rangeRight = this.componentWidth - right * this.stepSize;
      this.range.classList.remove('hide');
      this.range_from = left;
      this.range_to = right;
    },

    getTimes: function () {
      this.periods = [];
      let step = 0;
      for (let i = this.rangeTimeline[0]; i < this.rangeTimeline[1]; i++) {
        for (let j = 0; j < this.step; j++) {
          this.periods.push({
            ts: i * this.stepTime + j * this.stepTime / this.step,
            hour: j === 0,
            step: step,
          });
          step++;
        }
      }
      return this.periods;
    },

    setLimit: function () {
      this.limit = this.part_show === 4 ? 19 : 16;
    },

    moveTimeline: function (step) {
      this.start += step;
      this.setLimit();
      if (this.start < 0) {
        this.start = 0;
      }
      if (this.start > this.limit) {
        this.start = this.limit;
      }
      this.setStep = this.start;
    },

    hideAllBalloons: function (balloon = null) {
      const myNodeList = document.body.querySelectorAll('.timeline-balloon');
      for (let i = 0; i < myNodeList.length; ++i) {
        const item = myNodeList[i];
        if (balloon === item) continue;
        item.classList.add('hide');
      }
    },

    hideAllRanges: function (range = null) {
      const myNodeList = document.body.querySelectorAll('.range');
      for (let i = 0; i < myNodeList.length; ++i) {
        const item = myNodeList[i];
        if (range === item) continue;
        item.classList.add('hide');
      }
    },

    addRange: function (event) {
      this.hideAllRanges();
      const pos = this.getInStepPos(event.target.offsetLeft);
      document.body.classList.add('noSelect');
      this.range.classList.remove('hide');
      this.rangeLeft = pos;
      this.rangeRight = this.componentWidth - pos - this.stepSize;
      this.control = this.range_right;
      this.moveByRange = true;

      this.hideAllBalloons();

      if (event.type === 'tap') {
        this.onMouseUp.call(this, event);
      }
    },

    onMouseDown: function (event) {
      this.control = event.target;
      document.body.classList.add('noSelect');
    },

    onMouseMove: function (event) {
      if (!this.control) {
        return;
      }

      let mouseX = event.center.x - this.pageOffset + this.scrollContainer.scrollLeft;

      if (this.moveByRange && mouseX < this.rangeLeft) {
        this.control = this.range_left;
      }

      const isLeft = this.control.classList.contains('left');

      if (mouseX < 0) {
        mouseX = 0;
      } else if (mouseX > this.componentWidth) {
        mouseX = this.componentWidth;
      }

      const pos = this.getInStepPos(mouseX);
      this.updateRangeOptions(isLeft, pos);

      // this.bookingTime = this.getBookingTime();
    },

    updateRangeOptions: function (isLeft, pos) {
      if (isLeft) {
        if (pos > this.componentWidth - this.rangeRight - this.stepSize) {
          pos = this.componentWidth - this.rangeRight - this.stepSize;
        }
        this.rangeLeft = pos;
      } else {
        if (pos < this.rangeLeft + this.stepSize) {
          pos = this.rangeLeft + this.stepSize;
        }
        this.rangeRight = this.componentWidth - pos;
      }

      const range_from = this.getStepByPos(this.rangeLeft);
      const range_to = this.getStepByPos(this.rangeLeft + this.range.offsetWidth);

      const { from, to } = this.getPeriod(range_from, range_to);

      const multiplicity = (from / 60) % 15 === 0 && (to / 60) % 15 === 0;

      this.updateRangeWrong(multiplicity && this.isValidRange(from, to));
    },

    onMouseUp: function () {
      if (!this.control) {
        return true;
      }

      document.body.classList.remove('noSelect');
      this.control = null;
      this.moveByRange = false;

      setTimeout(() => {
        this.range_from = this.getStepByPos(this.rangeLeft);
        this.range_to = this.getStepByPos(this.rangeLeft + this.range.offsetWidth);
        this.is_valid_range = this.isValidRange();

        this.updateRangeWrong(this.is_valid_range);

        if (this.is_book) {
          this.updateBalloon();
        } else {
          this.sendDataAboutRange();
        }

        this.rangePeriod = this.getPeriod();
      });

      setTimeout(() => {
        this.$store.commit('roomsServices/SelectedDatePeriod', {
          rangePeriod: this.rangePeriod,
          timestamp: this.timestamp
        });

        if (this.$route.name !== 'Room' && this.is_valid_range) {
          this.$store.dispatch('user/GetAvailableResources', {
            resource_id: this.room_id,
            start: this.rangePeriod.from,
            end: this.rangePeriod.to
          });
        }
      }, 200)
    },

    updateRangeWrong: function (valid) {
      if (valid) {
        if (this.range.classList.contains('wrong')) {
          this.range.classList.remove('wrong');
        }
      } else {
        if (!this.range.classList.contains('wrong')) {
          this.range.classList.add('wrong');
        }
      }
    },

    sendDataAboutRange: function () {
      const period = this.getPeriod();
      const data = {
        ts_from: period.from,
        ts_to: period.to,
        booking_time: this.getBookingTime(),
        booking_cost: 0,
        free_seconds: 0,
        is_valid_range: this.is_valid_range,
        room_id: this.room_id,
        time: this.timestamp,
        rate: this.bookingPrice + ' ' + this.bookingCurrency,
        // min_booking_time: this.strToInt(this.min_booking_time),
        // booking_step: this.strToInt(this.booking_step),
        min_booking_time: this.min_booking_time,
        booking_step: this.booking_step,
        min_booking_time_error: this.min_booking_time_error,
        booking_step_error: this.booking_step_error,
      };

      return this.calculatePrice()
        .then(({seconds, cost}) => {
          data.free_seconds = seconds;
          data.booking_cost = cost;
          this.selectRange = data;
        });
    },

    getStepByPos: function (pos) {
      return Math.round(pos / this.stepSize);
    },

    getInStepPos: function (pos) {
      return this.getStepByPos(pos) * this.stepSize;
    },

    getPeriod: function (range_from, range_to) {
      range_from = range_from || this.range_from;
      range_to = range_to || this.range_to;

      const from = this.periods[range_from].ts;
      const to = range_to < this.periods.length
        ? this.periods[range_to].ts
        : (this.periods[this.periods.length - 1].ts + this.stepTime / this.step);

      return {
        from: this.timestamp + from,
        to: this.timestamp + to
      };
    },

    getBookingDate: function () {
      const dt = new Date(this.timestamp * 1000);
      return ('0' + dt.getUTCDate()).slice(-2) + '.' + ('0' + (dt.getUTCMonth() + 1)).slice(-2) + '.' + dt.getUTCFullYear();
    },

    getBookingTime: function () {
      let period = this.getPeriod();
      let dt_from = (new Date(period.from * 1000));
      let dt_to = (new Date(period.to * 1000));
      let str = '';
      str += ('0' + dt_from.getUTCHours()).slice(-2) + ':' + ('0' + dt_from.getUTCMinutes()).slice(-2);
      str += ' — ';
      str += ('0' + dt_to.getUTCHours()).slice(-2) + ':' + ('0' + dt_to.getUTCMinutes()).slice(-2);

      return str;
    },

    calculatePrice: async function () {
      const {from, to} = this.getPeriod();

      let roomId = !this.isRoom ? this.room_id : this.$route.params.id;
      let seconds = 0;
      // let seconds;
      // let caught = false;
      // let freeTime;
      // try {
      //     freeTime = await Api.get('/v1/meeting-rooms/get-available-time/' + roomId, { start: from, end: to });
      // } catch (e) {
      //   caught = true;
      //   seconds = 0;
      // }
      // if (!caught) {
      //   seconds = await freeTime.data.seconds;
      // }
      if (to - seconds <= from) {
        return {seconds: seconds, cost: 0};
      } else {
        let data = await Api.get('/v2/price/meeting-rooms', {
          ids: roomId,
          from: from,
          to: to - seconds,
        });
        return ({seconds: seconds, cost: data.data[0]['totalCost']});
      }
    },

    onScroll: function () {
      this.balloon.classList.add('hide');
    },

    clickRange: function () {
      if (this.is_book) {
        this.balloon.classList.remove('hide');
        this.updateBalloonPosition();
        // this.updateBalloon();
      }
    },

    isBooked: function (ts) {
      if (!this.booked || this.booked.length === 0) {
        return false;
      }
      ts = Math.round(ts / 60) * 60;
      return !!this.booked_cache[ts];
    },

    isValidRange: function (from, to) {
      const period = this.getPeriod();

      let start = (from ? from : period.from);
      let end = (to ? to : period.to);

      this.min_booking_time_error = false;
      this.booking_step_error = false;

      if (this.min_booking_time_int && this.min_booking_time_int > Math.abs(end - start)) {
        this.min_booking_time_error = true;
        return false;
      }

      if (this.booking_step_int && Math.floor(Math.abs(end - start) % this.booking_step_int) !== 0) {
        this.booking_step_error = true;
        return false;
      }

      if (!this.booked || this.booked.length === 0) {
        return true;
      }

      while (start < end) {
        if (this.isBooked(start)) {
          return false;
        }
        start += 900;
      }
      return true;
    },

    update: function () {
      if (this.node.offsetWidth < 450) {
        this.part_show = 4;
      } else {
        this.part_show = 3;
      }

      this.componentWidth = this.node.offsetWidth * this.part_show;
      this.pageOffset = this.node.getBoundingClientRect().left;
      this.stepSize = this.componentWidth / (this.rangeTimeline[1] * this.step);
      this.updateRange();
      this.updateBalloonPosition();
    },

    updateBalloon: function () {
      if (!this.is_book) {
        return;
      }

      this.balloon.classList.remove('hide');
      this.bookingDate = this.getBookingDate();
      this.bookingTime = this.getBookingTime();
      this.bookingCurrency = this.rate.currency.symbol;

      return this.calculatePrice()
          .then( ({ cost }) => {
            this.bookingPrice = cost;
            this.updateBalloonPosition();
          });
    },

    updateBalloonPosition: function () {
      if (!this.is_book) {
        return;
      }

      const halfWidth = this.balloon.offsetWidth / 2;
      this.balloonLeft = this.rangeLeft + (this.range.offsetWidth - this.balloon.offsetWidth) / 2 - this.scrollContainer.scrollLeft;
      if (this.balloonLeft > this.node.offsetWidth - halfWidth) {
        this.balloonLeft = this.node.offsetWidth - halfWidth;
      } else if (this.balloonLeft < halfWidth * -1) {
        this.balloonLeft = halfWidth * -1;
      }
    },

    updateRange: function () {
      if (this.rangeLeft && this.rangeRight) {
        this.rangeLeft = this.range_from * this.stepSize;
        this.rangeRight = this.componentWidth - this.range_to * this.stepSize;
      }
    },

    onDestroy: function () {
      this.sub.unsubscribe();
    },

    toggleBalloon: function () {
      if (!this.is_book) {
        return;
      }
      this.balloon.classList.toggle('hide');
      this.hideAllBalloons(this.balloon);
    },

    updateBooked: function () {
      this.rangeLeft = this.range_from * this.stepSize;
      this.rangeRight = this.componentWidth - this.range_to * this.stepSize;
    },

    onBookIt: function () {
      const period = this.getPeriod();

      this.balloon.classList.add('hide');
      this.range.classList.add('hide');

      this.booked.push({
        ts_start: period.from,
        ts_end: period.to,
      })

      const data = {
        ts_from: period.from,
        ts_to: period.to,
        room_id: this.room_id,
      };
    },

    strToInt: function (str) {
      try {
        const match = str.match(/^([0-1]\d|2[0-3]):(00|15|30|45)$/);
        return match[1] * 3600 + match[2] * 60;
      } catch (e) {
        return null;
      }
    },

    setTimestamp: function (timestamp) {
      this.timestamp = timestamp;
      if (this.timestamp < (Date.now() / 1000)) {
        this.step_timeline = Math.floor(((Date.now() / 1000) - this.timestamp) / 60 / 15 / 3);
      }
    },

    startIn: function (item) {
      this.start = item;
      if (this.scrollContainer) {
        this.scrollContainer.scrollLeft = Math.round(this.start * this.stepSize * this.step);
        this.balloon.classList.add('hide');
        this.updateBalloonPosition();
      }
    },

    bookedIn: function (item) {
      this.booked = item || [];
      this.booked_cache = {};
      this.booked_monolith = [];

      for (const t of this.booked) {
        const end_m = Math.round(t.ts_end / 60);
        let start_m = Math.round(t.ts_start / 60);
        while (start_m < end_m) {
          this.booked_cache[start_m * 60] = true;
          start_m += 15; // TODO Когда по 30 минут, устанавливаем значение 30.
        }

        this.booked_monolith.push({
          start: Math.round((t.ts_start - this.timestamp) / 60 / 15),
          end: Math.round((t.ts_end - this.timestamp) / 60 / 15)
        });
      }

      if (this.range_to && this.range_from) {
        this.is_valid_range = this.isValidRange();
      }
    },

    roomIn: function (room) {
      this.room_id = room.id;
      this.rate = room.rate;

      this.min_booking_time = room.min_booking_time;
      // if (room.location) this.min_booking_time_int = room.location.min_booking_time;
      let a = room.min_booking_time.split(':');
      this.min_booking_time_int = (+a[0]) * 60 * 60 + (+a[1]) * 60;

      this.booking_step = room.booking_step;
      // if (room.location) this.booking_step_int = room.location.booking_step;
      let b = room.booking_step.split(':');
      this.booking_step_int = (+b[0]) * 60 * 60 + (+b[1]) * 60;
    },
  },
  watch: {
    timestamp: function (newValue, oldValue) {
      if (this.timestamp && newValue && !oldValue && newValue !== oldValue) {
        if (this.is_book && this.range_from && this.range_to) {
          this.updateBalloon();
        } else {
          this.sendDataAboutRange();
        }
      }
    },
    is_valid_range(newVal) {
      this.$emit('isValidRange', newVal);
    }
  },
};
</script>

<style lang="scss">
  $timeline-step-width: 36px;
  $timeline-step-height: 60px;
  $timeline-hour-height: 20px;

  .tooltip {
    opacity: 1!important;
  }

  .app-timeline {
    position: relative;
    border-bottom: 1px solid #e1e4e9;
    margin: 0 30px;

    .timeline-arrow {
      position: absolute;
      z-index: 11;
      bottom: 0;
      height: $timeline-step-height;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 13px;
        height: 20px;
        margin-top: -10px;
        top: 50%;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAUCAYAAABWMrcvAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAS9JREFUeNqck00rRVEUhvfezE74A/6EmdRNDEg+uoaIW4Y+Boz4BYwY+BgqHzFUZEBxk1JGJrplKjPd45bM9jl5dh3Fai+3vPW0d+86b3ufddaxtdqTybPs6D1NR1ir1tpxoyjPc5O0J8axOW2k6VTmfQeBMrUz00SuUa+XvffGOfftjcLxnyFO6CbwIfwJOFBD1rkH1l74FLVp2I+GivURBsCL+gzsaKGgexiETDwzB1taKOgG+kJ3hb8AG1oo6A7GIv4SrGmhoAsowavwV2DPmX9ICw0X1+wU/jrMxkIlZZQ2YTV2Uj9UI/42LMeu1wOX0CICu7AYe6cuuIJWEQjzNx9rxBDcQiJqh1DRuhc60ib8k2Lu1JaH/+f5h3cOk82+00vR5je4Vkbol74EGACW3Et+o0nsDAAAAABJRU5ErkJggg==');
      }

      &.left {
        margin-right: 4px;

        &, &:before {
          left: -15px;
        }

        &:before {
          -moz-transform: rotate(180deg); /* Для Firefox */
          -ms-transform: rotate(180deg); /* Для IE */
          -webkit-transform: rotate(180deg); /* Для Safari, Chrome, iOS */
          -o-transform: rotate(180deg); /* Для Opera */
          transform: rotate(180deg);
        }
      }

      &.right {
        margin-left: 4px;

        &, &:before {
          right: -15px;
        }
      }
    }

    .timeline-scroll {
      height: $timeline-step-height * 2;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .timeline-container {
      width: 100%;
      height: $timeline-hour-height + $timeline-step-height;
      overflow: hidden;
    }

    .hours,
    .steps {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        &:before,
        &:after {
          display: table;
          content: "";
          line-height: 0;
        }

        &:after {
          clear: both;
        }
      }
    }

    .hour {
      float: left;
      //border-left: 1px solid #e1e4e9;
      //box-shadow: inset 1px 0 0 #e1e4e9;
      width: $timeline-step-width * 2;
      height: $timeline-hour-height;
      color: #2f353b;
      font-size: 12px;
      padding-left: 5px;
      padding-right: 7px;
      background: #fff;
      position: relative;
      top: 16px;
    }

    .steps {
      position: relative;

    }

    .step {
      float: left;
      //border-left: 1px solid #e1e4e9;
      //box-shadow: inset 1px 0 0 #e1e4e9;
      //background: #f8f8f8;
      width: $timeline-step-width;
      height: $timeline-step-height;
      position: relative;
      font-size: 8px;

      &:before {
        content: '';
        display: block;
        width: 1px;
        height: $timeline-step-height * 2 / 4;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #e1e4e9;
      }

      &.hours {
        &:after {
          content: '';
          display: block;
          width: 1px;
          height: $timeline-step-height;
          overflow: hidden;
          position: absolute;
          bottom: 0;
          left: 0;
          background: #e1e4e9;
        }
      }

      &.minutes {
        border-left-color: transparent;
        box-shadow: none;
      }

      &.booked {

      }
    }

    .booked2{
      position: absolute;
      z-index: 0;
      bottom: 0;
      height: $timeline-step-height * 2 / 4;
      background: url('data:image/gif;base64,R0lGODlhBgAGALMAAMfHx+vr68XFxePj4/Dw8O3t7dDQ0N3d3cTExPv7++zs7Nzc3Pr6+gAAAAAAAAAAACH5BAAAAAAALAAAAAAGAAYAAAQTULG50GRLSIx2DdMBFJdBJOgQAQA7');
      filter: opacity(0.5);

    }
    .background{
      position: absolute;
      z-index: 0;
      bottom: 0;
      height: $timeline-step-height;
      // background: #f8f8f8;
    }
    .range {
      position: absolute;
      z-index: 10;
      bottom: 0;
      //height: $timeline-step-height;
      // background: #f4d169;
      // background: rgba(242, 193, 44, 0.7);
      background: #1BC5BD;
      background: rgba(27, 197, 189, 0.5);
      // border-left: 1px solid #c39e4e;
      // border-right: 1px solid #c39e4e;
      height: 30px;

      .range-drag {
        pointer-events: auto;
        position: absolute;
        top: 62%;
        display: block;
        margin-top: -0.5 * $timeline-hour-height;
        //width: $timeline-hour-height;
        //height: $timeline-hour-height;
        // background: #f4d169;
        background: #1BC5BD;
        // border: 1px solid #c39e4e;
        border: 1px solid #1DBAB3;
        //cursor: ew-resize;
        cursor: col-resize;
        border-radius: 100%;
        height: 12px;
        width: 12px;

        &.left {
          left: (-0.25 * $timeline-hour-height);
        }

        &.right {
          right: (-0.25 * $timeline-hour-height);
        }
      }

      &.wrong {
        border-color: #ce5557;
        background: #e78d8d;
        background: rgba(215, 34, 35, 0.5);

        .range-drag {
          border-color: #ce5557;
          background: #e78d8d;
        }
      }
    }

    .timeline-balloon {
      position: absolute;
      z-index: 20;
      //border: 2px solid #ccc;
      background: #fff;
      bottom: 100%;
      min-width: 260px;
      margin-bottom: -5px;
      box-shadow: 5px 5px 0 rgba(0,0,0,0.05);

      .wrong {
        color: #ce5557;
      }

      &-title,
      &-content {
        padding: 10px 20px;
      }

      &-title {
        background: #f7f7f7;
        border-bottom: 1px solid #ebebeb;
        text-align: center;
      }

      &-content {
        text-align: center;
      }

      &-arrow {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 16px;
        height: 16px;
        margin-left: -8px;
        margin-top: -6px;
        background: #fff;
        border-right:  2px solid #ccc;
        border-bottom: 2px solid #ccc;
        -moz-transform: rotate(45deg); /* Для Firefox */
        -ms-transform: rotate(45deg); /* Для IE */
        -webkit-transform: rotate(45deg); /* Для Safari, Chrome, iOS */
        -o-transform: rotate(45deg); /* Для Opera */
        transform: rotate(45deg);
      }
    }
    .timeline-balloon.hide {
      display: none !important;
    }
  }
</style>
