<template>
  <div class="card card-custom">
    <div class="card-body p-6 text-center d-flex align-items-center justify-content-center font-italic">
      {{ message || $t('Empty') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
      default: undefined,
    }
  }
}
</script>