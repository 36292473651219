export default [
    {
        path: '/services',
        title: 'tariffs',
        name: 'tariffs',
        link: true,
        component: () => import('./views/Tariffs.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'services',
            icon: 'mdi-account',
            menu: [
                {
                    path: '/services',
                    title: 'tariffs',
                    name: 'tariffs',
                    link: true,
                },
                {
                    path: '/services/rooms',
                    title: 'meeting_rooms',
                    name: 'rooms',
                    link: true,
                }
            ],
        },
        children: [],
    },
    {
        path: '/services/rooms',
        title: 'meeting_rooms',
        name: 'rooms',
        link: false,
        component: () => import('./views/Rooms.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'meeting_rooms',
            icon: 'mdi-account',
            parent: 'services'
        }
    },
    {
        path: '/services/room/:id',  // ;time=:time;from=:from;to=:to
        title: 'meeting_rooms',
        name: 'Room',
        link: false,
        component: () => import('./views/Room.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'meeting_rooms',
            icon: 'mdi-account',
            parent: 'services'
        }
    },
    {
        path: '/services/purchase-tariff/:id',
        title: 'purchase_tariff',
        name: 'purchase_tariff',
        link: false,
        component: () => import('./views/PurchaseTariff.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'purchase_tariff',
            icon: 'mdi-account',
            parent: 'tariffs'
        }
    },
]
