<template>
  <b-modal v-model="dialogRequestToSupportModal" size="md"
           @ok="sendRequestToSupport"
           @hidden="hideAskAdminModal"
           :title="$t('quickUser.support.NewQuestion')"
           :ok-title="$t('quickUser.support.Send')"
           :cancel-title="$t('quickUser.support.Cancel')">
    <template #modal-footer="{ ok, cancel, hide }">
      <b-button v-if="dialogRequestToSupportStatus == null" size="sm" variant="success" @click="sendRequestToSupport()"
                :disabled="sendRequestSupportProcessing">{{ $t('quickUser.support.Send') }}</b-button>
      <b-button v-if="dialogRequestToSupportStatus == true" size="sm" variant="success" @click="showDialogRequestToSupportModal()"
                :disabled="sendRequestSupportProcessing">{{ $t('quickUser.support.SendMore') }}</b-button>
      <b-button v-if="dialogRequestToSupportStatus == false" size="sm" variant="success" @click="showDialogRequestToSupportModal()"
                :disabled="sendRequestSupportProcessing">{{ $t('quickUser.support.SendAgain') }}</b-button>
      <b-button size="sm" variant="" @click="hideAskAdminModal">{{ $t('quickUser.support.Cancel') }}</b-button>
    </template>
    <div v-if="dialogRequestToSupportStatus == null" class="d-flex flex-column align-items-center">
      <form>
        <p>{{ $t('quickUser.support.SupportRequestDescription') }}</p>
        <div class="form-group">
          <label for="selectLocationId">{{ $t('quickUser.support.Space') }}</label>
          <b-form-select
              id="selectLocationId"
              class="form-control"
              v-model="location_id"
              :options="locationsList"
              :disabled="sendRequestSupportProcessing"
          ></b-form-select>
        </div>
        <div class="form-group">
          <label for="selectMessage">{{ $t('quickUser.support.Question') }}</label>
          <b-form-textarea
              id="selectMessage"
              class="form-control"
              v-model="requestSupportMessage"
              :placeholder="$t('quickUser.support.EnterYourQuestion')"
              :disabled="sendRequestSupportProcessing"
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </div>
      </form>
    </div>
    <div v-if="dialogRequestToSupportStatus == false" class="d-flex flex-column align-items-center">
      <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
        <div class="symbol-label">
          <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
        </div>
      </div>
      <h3>{{ $t('quickUser.support.RequestErrorSending') }}</h3>
      <p>{{ $t('quickUser.support.RequestSendTryAgain') }}</p>
    </div>
    <div v-if="dialogRequestToSupportStatus == true" class="d-flex flex-column align-items-center">
      <div class="symbol symbol-50 symbol-light-success mb-4 flex-shrink-0">
        <div class="symbol-label">
          <font-awesome-icon icon="check" class="text-success icon-lg"/>
        </div>
      </div>
      <h3>{{ $t('quickUser.support.RequestSuccessfully') }}</h3>
      <p>{{ $t('quickUser.support.ReceiveAResponse') }}</p>
    </div>
  </b-modal>
</template>

<script>
import api from "@/plugins/api";
import {mapGetters} from "vuex";

export default {
  name: "AskAdminQuestionModal",
  computed: {
    ...mapGetters({
      locations: 'locationOrder/list',
    }),
  },
  data: () => ({
    dialogRequestToSupportModal: false,
    sendRequestSupportProcessing: false,
    dialogRequestToSupportStatus: null,
    location_id: null,
    locationsList: [],
    requestSupportMessage: '',
  }),
  props: {
    showAskAdminModal: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    locations(val) {
      if (val.length > 0) {
        for (let index in val) {
          if (!this.location_id) this.location_id = val[index].id;
          this.locationsList.push({value: val[index].id, text: val[index].name});
        }
      }
    },
    showAskAdminModal(val) {
      this.dialogRequestToSupportModal = val;
      // this.sendRequestSupportProcessing = false
      // this.dialogRequestToSupportStatus = null
    },
  },
  methods: {
    showDialogRequestToSupportModal() {
      this.dialogRequestToSupportModal = true
      this.sendRequestSupportProcessing = false
      this.dialogRequestToSupportStatus = null
    },
    sendRequestToSupport() {
      let self = this
      self.dialogRequestToSupportModal = true
      self.sendRequestSupportProcessing = true
      self.dialogRequestToSupportStatus = null
      api.post('/v2/users/send-email', {
        message: self.requestSupportMessage,
        type: 'coworking',
        // coworking_id: 1,
        location_id: self.location_id,
      })
          .then(() => {
            self.requestSupportMessage = ''
            self.sendRequestSupportProcessing = false
            self.dialogRequestToSupportModal = true
            self.dialogRequestToSupportStatus = true
          }).catch(reason => {
        console.error(reason)
        let _error = []
        try {
          if (reason.response.data.error && reason.response.data.error.extra) {
            for (let i in reason.response.data.error.extra) {
              _error.push(reason.response.data.error.extra[i][0])
            }
          }
        } catch (e) {}
        self.requestSupportError = _error.join('<br>')
        self.sendRequestSupportProcessing = false
        self.dialogRequestToSupportStatus = false
        self.dialogRequestToSupportModal = true
      })
    },
    hideAskAdminModal() {
      this.dialogRequestToSupportModal = false;
      this.sendRequestSupportProcessing = false;
      this.dialogRequestToSupportStatus = null;
      this.$emit('updateDialogRequestToSupportModal', false);
    }
  }
}
</script>

<style scoped>

</style>
