<template>
  <!-- begin::User Panel-->
  <div>
    <div id="kt_quick_user" class="offcanvas offcanvas-right offcanvas-w-375 p-10" :class="showPanel ? 'offcanvas-on' : ''"
       v-if="isAuthenticated">
    <!--begin::Header-->
    <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
      <h3 class="font-weight-bolder m-0">{{ $t('quickUser.profile') }}</h3>
      <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close"
         @click="toggleQuickUser">
        <i class="ki ki-close icon-xs text-muted"></i>
      </a>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <div class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative; overflow-y: auto;">
      <!--begin::Header-->
      <div class="d-flex align-items-center mt-5">
        <div class="symbol symbol-100 mr-5">
          <div v-if="identity.avatar && !identity.avatar.is_default" class="symbol-label"
               :style="'background-image:url(' + identity.avatar.url + ')'"></div>
          <span v-else class="font-size-h3 symbol-label font-weight-boldest">{{ firstLettersFio }}</span>
        </div>
        <div class="d-flex flex-column">
          <a href="#" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ identity.fio }}</a>
          <div class="text-muted mt-1">{{ identity.phone }}</div>
          <div class="navi mt-2">
            <a href="#" class="navi-item">
								<span class="navi-link p-0 pb-2">
									<span class="navi-icon mr-1">
										<span class="svg-icon svg-icon-lg svg-icon-primary">
											<!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg-->
											<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                           width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
												<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
													<rect x="0" y="0" width="24" height="24"/>
													<path
                              d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                              fill="#000000"/>
													<circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5"/>
												</g>
											</svg>
                      <!--end::Svg Icon-->
										</span>
									</span>
									<span class="navi-text text-muted text-hover-primary">{{ identity.email }}</span>
								</span>
            </a>
            <a href="#" @click="logout"
               class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">{{ $t('quickUser.Sign-out') }}</a>
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Separator-->
      <div v-if="coworkingData.is_online_payment_enabled" class="separator separator-dashed mt-8 mb-5"></div>
      <!--end::Separator-->
      <!--begin::Banks Card-->
      <div v-if="coworkingData.is_online_payment_enabled">
        <!--begin:Heading-->
        <h5 class="mb-5">{{ $t('quickUser.cards') }}</h5>
        <!--end:Heading-->
        <p v-if="cards.length <= 0" class="text-muted">{{ $t('quickUser.not_have_cards_yet') }}</p>
        <!--begin::Item-->
        <div v-for="card in cards" :key="card.id" class="d-flex align-items-center bg-light-light rounded px-5 py-4 gutter-b">
          <font-awesome-icon icon="credit-card" class="icon-md mr-2  text-success"/>
          <div class="d-flex flex-column flex-grow-1 mr-2 mt-1">
            <span class="font-weight-bolder font-size-lg text-uppercase">{{ card.card_masked }}</span>
          </div>
          <a href="#" @click="showDialogDropCardModal(card.id)" class="font-weight-bolder text-warning text-dark-50 text-hover-dark font-size-lg">{{
              $t('quickUser.Delete')
            }}</a>
        </div>
        <!--end::Item-->
      </div>
      <!--end::Banks Card-->
      <!--begin::Separator-->
      <div v-if="!isPassWidget && btnGuestPass" class="separator separator-dashed my-5"></div>
      <!--end::Separator-->
      <!--begin::Guest Card-->
      <div v-if="!isPassWidget && btnGuestPass">
        <!--begin:Heading-->
        <h5 class="mb-5">{{ $t('quickUser.guestCard.Guests') }}</h5>
        <!--end:Heading-->
        <!--begin::Item-->
        <div class="d-flex align-items-center bg-light-light rounded px-5 py-4 gutter-b">
          <font-awesome-icon icon="ticket-alt" class="icon-md mr-3"/>
          <div class="d-flex flex-column flex-grow-1 mr-2 mt-1">
            <span @click="showDialogRequestToGuestCardModal"
                  class="font-weight-normal text-dark-75 text-hover-primary font-size-lg cursor-pointer">{{
                $t('quickUser.guestCard.OrderPass')
              }}</span>
          </div>
        </div>
        <!--end::Item-->
      </div>
      <!--end::Guest Card-->
      <!--begin::Separator-->
      <div class="separator separator-dashed my-5"></div>
      <!--end::Separator-->
      <!--begin::Banks Card-->
      <div>
        <!--begin:Heading-->
        <h5 class="mb-5">{{ $t('quickUser.Settings') }}</h5>
        <!--end:Heading-->
        <!--begin::Item-->
        <div class="d-flex align-items-center bg-light-light rounded px-5 py-4 mb-5">
          <font-awesome-icon icon="user-edit" class="icon-md mr-3"/>
          <div class="d-flex flex-column flex-grow-1 mr-2 mt-1">
            <router-link
                to="/profile-settings"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <a :href="href" @click="navigate"
                 class="font-weight-normal text-dark-75 text-hover-primary font-size-lg">{{
                  $t('quickUser.ProfileSettings')
                }}</a>
            </router-link>
          </div>
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div class="d-flex align-items-center bg-light-light rounded px-5 py-4 mb-5">
          <font-awesome-icon icon="bell" class="icon-md mr-3"/>
          <div class="d-flex flex-column flex-grow-1 mr-2 mt-1">
            <router-link
                to="/notification-settings"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <a :href="href" @click="navigate"
                 class="font-weight-normal text-dark-75 text-hover-primary font-size-lg">{{
                  $t('quickUser.NotificationSettings')
                }}</a>
            </router-link>
          </div>
        </div>
        <!--end::Item-->
      </div>
      <div class="separator separator-dashed my-5"></div>
      <div>

      </div>
      <!--end::Banks Card-->
      <div v-if="!btnAskQuestion">
        <!--begin:Heading-->
        <h5 class="mb-5">{{ $t('quickUser.support.Support') }}</h5>
        <!--end:Heading-->
        <!--begin::Item-->
        <div class="d-flex align-items-center bg-light-light rounded px-5 py-4 gutter-b">
          <font-awesome-icon icon="question-circle" class="icon-md mr-3"/>
          <div class="d-flex flex-column flex-grow-1 mr-2 mt-1">
            <span @click="showDialogRequestToSupportModal"
               class="font-weight-normal text-dark-75 text-hover-primary font-size-lg cursor-pointer">{{
                $t('quickUser.support.AskAQuestion')
              }}</span>
          </div>
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <template v-if="integrations && integrations.indexOf('zendesk') > -1">
          <div class="d-flex align-items-center bg-light-light rounded px-5 py-4 gutter-b">
            <font-awesome-icon icon="question-circle" class="icon-md mr-3"/>
            <div class="d-flex flex-column flex-grow-1 mr-2 mt-1">
              <router-link
                  to="/jwt-login"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
              >
                <a :href="href" @click="navigate"
                   class="font-weight-normal text-dark-75 text-hover-primary font-size-lg">{{
                    $t('quickUser.support.HelpCenter')
                  }}</a>
              </router-link>
            </div>
          </div>
        </template>
        <!--end::Item-->
      </div>
      <!--end::Banks Card-->
    </div>
    <!--end::Content-->
    <b-modal v-model="dialogDropCardModal" size="md" @ok="dropCardSelected"
             :title="$t('quickUser.confirmDialog.title')"
             :ok-title="$t('quickUser.confirmDialog.ok')"
             :cancel-title="$t('quickUser.confirmDialog.cancel')">
      <div class="d-flex flex-column align-items-center">
        <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
          <div class="symbol-label">
            <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
          </div>
        </div>
      </div>
    </b-modal>

    <!--begin::Guest Card Modal-->
    <GuestCardModal
        :showGuestCardModal="showGuestCardModal"
        @updateDialogRequestToGuestCardModal="updateDialogRequestToGuestCardModal"/>
    <!--end::Guest Card Modal-->

    <b-modal v-model="dialogRequestToSupportModal" size="md"
             @ok="sendRequestToSupport"
             :title="$t('quickUser.support.NewQuestion')"
             :ok-title="$t('quickUser.support.Send')"
             :cancel-title="$t('quickUser.support.Cancel')">
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button v-if="dialogRequestToSupportStatus == null" size="sm" variant="success" @click="sendRequestToSupport()"
                  :disabled="sendRequestSupportProcessing">{{ $t('quickUser.support.Send') }}</b-button>
        <b-button v-if="dialogRequestToSupportStatus == true" size="sm" variant="success" @click="showDialogRequestToSupportModal()"
                  :disabled="sendRequestSupportProcessing">{{ $t('quickUser.support.SendMore') }}</b-button>
        <b-button v-if="dialogRequestToSupportStatus == false" size="sm" variant="success" @click="showDialogRequestToSupportModal()"
                  :disabled="sendRequestSupportProcessing">{{ $t('quickUser.support.SendAgain') }}</b-button>
        <b-button size="sm" variant="" @click="cancel()">{{ $t('quickUser.support.Cancel') }}</b-button>
      </template>
      <div v-if="dialogRequestToSupportStatus == null" class="d-flex flex-column align-items-center">
        <form>
          <p>{{ $t('quickUser.support.SupportRequestDescription') }}</p>
          <div class="form-group">
            <label for="selectLocationId">{{ $t('quickUser.support.Space') }}</label>
            <b-form-select
                id="selectLocationId"
                class="form-control"
                v-model="location_id"
                :options="locationsList"
                :disabled="sendRequestSupportProcessing"
            ></b-form-select>
          </div>
          <div class="form-group">
            <label for="selectMessage">{{ $t('quickUser.support.Question') }}</label>
            <b-form-textarea
                id="selectMessage"
                class="form-control"
                v-model="requestSupportMessage"
                :placeholder="$t('quickUser.support.EnterYourQuestion')"
                :disabled="sendRequestSupportProcessing"
                rows="3"
                max-rows="6"
            ></b-form-textarea>
          </div>
        </form>
      </div>
      <div v-if="dialogRequestToSupportStatus == false" class="d-flex flex-column align-items-center">
        <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
          <div class="symbol-label">
            <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
          </div>
        </div>
        <h3>{{ $t('quickUser.support.RequestErrorSending') }}</h3>
        <p>{{ $t('quickUser.support.RequestSendTryAgain') }}</p>
      </div>
      <div v-if="dialogRequestToSupportStatus == true" class="d-flex flex-column align-items-center">
        <div class="symbol symbol-50 symbol-light-success mb-4 flex-shrink-0">
          <div class="symbol-label">
            <font-awesome-icon icon="check" class="text-success icon-lg"/>
          </div>
        </div>
        <h3>{{ $t('quickUser.support.RequestSuccessfully') }}</h3>
        <p>{{ $t('quickUser.support.ReceiveAResponse') }}</p>
      </div>
    </b-modal>
  </div>
    <div :class="showPanel ? 'offcanvas-overlay' : ''" @click="toggleQuickUser"></div>
  </div>
  <!-- end::User Panel-->
</template>

<script>
import api from "@/plugins/api";

let cookie = require('vue-cookie');
import {mapState, mapActions, mapGetters} from 'vuex'

import GuestCardModal from "./GuestCardModal";

export default {
  components: { GuestCardModal },
  computed: {
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
      token: 'user/token',
      identity: 'user/identity',
      locations: 'locationOrder/list',
      firstLettersFio: 'user/firstLettersFio',
      integrations: 'main/integrations',
      coworkingData: 'spaces/coworkingById'
    }),
    ...mapState({
      showPanel: state => state.quick_user.show,
      identity: state => state.user.identity,
    })
  },
  watch: {
    locations(val) {
      if (val.length > 0) {
        for (let index in val) {
          if (!this.location_id) this.location_id = val[index].id;
          this.locationsList.push({value: val[index].id, text: val[index].name});
        }
      }
    }
  },
  data: () => ({
    fav: true,
    dialogDropCardModal: false,
    showGuestCardModal: false,
    dialogRequestToSupportModal: false,
    dialogRequestToSupportStatus: null,
    sendRequestSupportProcessing: false,
    requestSupportMessage: '',
    requestSupportError: null,
    selectedDropCardId: null,
    menu: false,
    message: false,
    location_id: null,
    locationsList: [],
    hints: true,
    cards: [],
    notifications: [
      'Mike, John responded to your email',
      'You have 5 new tasks',
      'You\'re now a friend with Andrew',
      'Another Notification',
      'Another One'
    ],
    title: null,
    isPassWidget: false,
    btnGuestPass: false,
    btnAskQuestion: false
  }),
  mounted() {
    this.btnGuestPass = window.__env.btn_guest_pass;
    this.btnAskQuestion = window.__env.widget_ask_admin_question;
    if (localStorage.getItem('coworkingId_' + localStorage.getItem('currentUserId'))) {
      let id = localStorage.getItem('coworkingId_' + localStorage.getItem('currentUserId'));
      this.$store.dispatch('spaces/GetCoworkingById', { id });
    }
    this.getCards()
  },
  methods: {
    ...mapActions('quick_user', [
      'toggleQuickUser'
    ]),
    showDialogDropCardModal(card_id) {
      this.selectedDropCardId = card_id
      this.dialogDropCardModal = true
    },
    showDialogRequestToGuestCardModal() {
      this.showGuestCardModal = true;
    },
    updateDialogRequestToGuestCardModal(value) {
      this.showGuestCardModal = value;
    },
    showDialogRequestToSupportModal() {
      this.dialogRequestToSupportModal = true
      this.sendRequestSupportProcessing = false
      this.dialogRequestToSupportStatus = null
    },
    sendRequestToSupport() {
      let self = this
      self.dialogRequestToSupportModal = true
      self.sendRequestSupportProcessing = true
      self.dialogRequestToSupportStatus = null
      api.post('/v2/users/send-email', {
          message: self.requestSupportMessage,
          type: 'coworking',
          // coworking_id: 1,
          location_id: self.location_id,
        })
        .then(() => {
          self.requestSupportMessage = ''
          self.sendRequestSupportProcessing = false
          self.dialogRequestToSupportModal = true
          self.dialogRequestToSupportStatus = true
        }).catch(reason => {
          console.error(reason)
          let _error = []
          try {
            if (reason.response.data.error && reason.response.data.error.extra) {
              for (let i in reason.response.data.error.extra) {
                _error.push(reason.response.data.error.extra[i][0])
              }
            }
          } catch (e) {}
          self.requestSupportError = _error.join('<br>')
          self.sendRequestSupportProcessing = false
          self.dialogRequestToSupportStatus = false
          self.dialogRequestToSupportModal = true
        })
    },
    async logout() {
      console.log('Logout')
      try {
        cookie.delete('auth_key');
        this.$store.commit('user/UpdateIdentity', {});
        this.$store.commit('user/CurrentToken', null);

        console.log('Logout refresh')
        this.$router.push('/')
        document.location.reload()
      } catch (e) {
        console.log(e)
        this.error = e.message
      }
    },
    getCards() {
      let self = this
      api.get('/v2/cards', {})
          .then(responce => {
            self.cards = responce.data
            if (this.integrations && this.integrations.indexOf('integration_otp') > -1) {
              this.isPassWidget = true;
            }
          }).catch(reason => {
        console.error('reason', reason)
      })
    },
    dropCardSelected() {
      let self = this
      api.delete('/v2/cards/' + self.selectedDropCardId, {})
          .then(responce => {
            this.getCards()
          }).catch(reason => {
        console.error('reason', reason)
      })
    }
  }
}
</script>

<style>
/* Fix coming in v2.0.8 */
#core-app-bar {
  width: auto;
}

.offcanvas-w-375 {
  width: 375px!important;
}

#core-app-bar a {
  text-decoration: none;
}
</style>
