import api from '@/plugins/api'
import moment from "moment/moment";

export default {
    namespaced: true,
    state: {
        loaded: false,
        packages: [],
        orders: [],
        bookings: [],
        events: [],
        offers: [],
        passes: [],
        targets: [],
        coins: {
            company: { //суммарные баллы всех юридических лиц, в которых работает сотрудник
                totalDeposit: null, //общее количество полученных за всё время
                totalSpent: null, //общее количество потраченных баллов за всё время
                coins: null
            },
            user: { //баллы физического лица
                totalDeposit: null, //общее количество полученных за всё время
                totalSpent: null, //общее количество потраченных баллов за всё время
                coins: null
            }
        },
    },
    getters: {
        loaded: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.loaded
        },
        packages: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.packages
        },
        orders: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.orders
        },
        bookings: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.bookings
        },
        events: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.events
        },
        offers: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.offers
        },
        passes: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.passes
        },
        targets: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.targets
        },
        coinsBalance: (state) => { //Текущий баланс
            const coins = state.coins.company.coins && state.coins.company.coins.length ? state.coins.company.coins : state.coins.user.coins
            return coins === null ? 0 : coins.reduce((amount, item) => amount + item.remain, 0)
        },
        coinsTotalDeposit: (state) => { //Количество баллов, полученные за всё время
            return state.coins.company.totalDeposit - state.coins.company.totalSpent > 0 ? state.coins.company.totalDeposit : state.coins.user.totalDeposit
        },
        coinsTotalSpent: (state) => { //Количество баллов, потраченные за всё время
            return state.coins.company.totalDeposit - state.coins.company.totalSpent > 0 ? state.coins.company.totalSpent : state.coins.user.totalSpent
        },
        coinsFirstLine: (state) => {
            const coins = state.coins.company.coins && state.coins.company.coins.length ? state.coins.company.coins : state.coins.user.coins
            return coins === null || coins.length < 1 ? 0 : coins[0]
        },
        loyaltyLevel: (state) => {
            return state.coins.company.level ? state.coins.company.level : state.coins.user.level
        }
    },
    mutations: {
        UpdateLoaded (state:any, loaded:any) {
            state.loaded = loaded
        },
        INIT_PACKAGES(state:any, packages:any) {
            state.packages = packages
        },
        INIT_ORDERS(state:any, orders:any) {
            state.orders = orders
        },
        INIT_BOOKINGS(state:any, bookings:any) {
            state.bookings = bookings
        },
        INIT_EVENTS(state:any, events:any) {
            state.events = events
        },
        INIT_OFFERS(state:any, offers:any) {
            state.offers = offers
        },
        INIT_PASSES(state:any, passes:any) {
            state.passes = passes
        },
        INIT_TARGETS(state:any, passes:any) {
            state.targets = passes
        },
        setCoins(state, data) {
            state.coins = data
        }
    },
    actions: {
        async fetchCoins({commit, state}) {
            if(state.coins.company.coins === null || state.coins.user.coins === null) {
                let link = '/v2/loyalty/coins'
                const coworkingID = localStorage.getItem('coworkingId_' + localStorage.getItem('currentUserId')) || null
                if(coworkingID) link += '?coworking_id=' + coworkingID
                const data = (await api.get(link)).data
                data.company.coins.forEach(item => item.active_to = new Date(Date.parse(item.active_to)))
                data.user.coins.forEach(item => item.active_to = new Date(Date.parse(item.active_to)))
                commit('setCoins', data)
            }
        },
        ClientInit ({commit, state}:{commit:any, state:any}, app:any) {},
        PackagesInit: ({commit}:any, locationsList:any, app:any) => {
            return new Promise((resolve, reject) => {
                let ids = locationsList.map(item => item.id);
                let data = {
                    expand: "rate,order",
                    not_spent: false,
                    // status_ids: 2,
                    sort: "id",
                    state: "current",
                    location_ids: ids.join(','),
                    'per-page': 100
                };
                // let data = {expand: "rate,order", not_spent: false, sort: "id"}
                api.get('/v2/packages', data)
                    .then(responce => {
                        resolve(responce.data);
                        commit('INIT_PACKAGES', responce.data);
                    }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        OrdersInit: ({commit}:any, app:any) => {
            return new Promise((resolve, reject) => {
                let data = {status_ids: 7, 'per-page': 500}
                api.get('/v2/orders', data)
                    .then(responce => {
                        resolve(responce.data);
                        commit('INIT_ORDERS', responce.data);
                    }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        BookingsInit: ({commit}:any, app:any) => {
            return new Promise((resolve, reject) => {
                let data = {status: 1, expand: 'order,room,location,resource'}
                api.get('/v2/bookings', data)
                    .then(responce => {
                        resolve(responce.data);
                        commit('INIT_BOOKINGS', responce.data);
                    }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        EventsInit: ({commit, rootState}:any, locationsList:any) => {
            return new Promise((resolve, reject) => {
                let ids = locationsList.map(item => item.id);
                api.get('/v2/coworking-events', {
                    'start[from]': moment().format(),
                    'location_id': ids
                    // 'location_id': rootState.user.identity.location_ids,
                })
                    .then(responce => {
                        resolve(responce.data);
                        commit('INIT_EVENTS', responce.data);
                    }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        OffersInit: ({commit}:any, locationsList:any) => {
            return new Promise((resolve, reject) => {
                let ids = locationsList.map(item => item.id);
                commit('UpdateLoaded', false);
                api.get('/v2/offers', {
                    expand: 'locations,tags',
                    location_ids: ids.join(',')
                })
                    .then(responce => {
                        resolve(responce.data);
                        commit('INIT_OFFERS', responce.data);
                        commit('UpdateLoaded', true);
                    }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        PassesInit: ({commit}:any, location_id:any) => {
            return new Promise((resolve, reject) => {
                commit('UpdateLoaded', false);
                api.get('/v2/otp/request', {
                    status: 2,
                    location_id
                }) // , data
                    .then(responce => {
                        resolve(responce.data);
                        commit('INIT_PASSES', responce.data);
                        commit('UpdateLoaded', true);
                    }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        TargetInit: ({commit}:any, location_id:any) => {
            return new Promise((resolve, reject) => {
                commit('UpdateLoaded', false);
                api.get('/v2/otp/target', {
                    location_id
                })
                    .then(responce => {
                        resolve(responce.data);
                        commit('INIT_TARGETS', responce.data);
                        commit('UpdateLoaded', true);
                    }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        PostNewPass: ({commit}:any, data:any) => {
            return new Promise((resolve, reject) => {
                commit('UpdateLoaded', false);
                api.post('/v2/otp/request', data)
                    .then(responce => {
                        resolve(responce.data);
                        commit('UpdateLoaded', true);
                    }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        }
    }
}

