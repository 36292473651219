import Vue from 'vue'
import Vuex from 'vuex'
// import upperFirst from 'lodash-es/upperFirst'
import camelCase from 'lodash-es/camelCase'
import api from "@/plugins/api";

Vue.use(Vuex)

const requireComponent = require.context(
    // Относительный путь до каталога компонентов
    '@/modules',
    // Обрабатывать или нет подкаталоги
    true,
    // Регулярное выражение для определения файлов базовых компонентов
    /[\w\d_\-.]+\.store\.(ts|js)$/
)
let modules: any = {};

for (let store of requireComponent.keys()) {
    let storeConfig:any = requireComponent(store)
    let paths = store.split('/');
    let storeKey : any = paths.pop();
    storeKey = camelCase(storeKey.split('.').shift())
    storeKey = storeKey == 'default' ? '' : storeKey
    let storeName:string = camelCase([storeKey, paths.pop()].join(' '))
    modules[storeName] = storeConfig.default
}

modules['navigation'] = {
    namespaced: true,
    state: {
        links: [],
        show: true,
        mini: false,
    },
    mutations: {
        LINKS(state:any, links:any) {
            links.sort(function (a:any, b:any) {
                if (a.weight == undefined) a.weight = 500
                if (b.weight == undefined) b.weight = 500
                return (a.weight - b.weight)
            })
            state.links = links
        },
        SHOW_NAVIGATION(state:any, show:boolean) {
            state.show = show
            // console.log('mutations.SHOW_NAVIGATION show', state.show, ', mini', state.mini, ', window.innerWidth', window.innerWidth)
        },
        TOGGLE_NAVIGATION(state:any) {
            if (window.innerWidth > 991) {
                state.mini = !state.mini
                state.show = true
            } else {
                state.mini = false
                state.show = !state.show
            }
            // console.log('mutations.TOGGLE_NAVIGATION show', state.show, ', mini', state.mini, ', window.innerWidth', window.innerWidth)
        }
    },
    actions: {
        ClientInit: ({commit}:any, app:any) => {
            let links : any = []
            for (let link of app.$router.options.routes) {
                if (link.link && !link.parent) {
                    if (link.name === 'events' && !window.__env.widget_events) {
                        continue;
                    } else if (link.name === 'offers' && !window.__env.widget_offers) {
                        continue;
                    } else {
                        links.push({
                            to: link.path,
                            icon: link.meta.icon,
                            name: link.name,
                            weight: link.meta.weight,
                            title: link.meta.title,
                            children: [],
                        })
                    }
                }
            }
            for (let link of app.$router.options.routes) {
                if (link.link && link.parent) {
                    for (let _ in links) {
                        if (links.hasOwnProperty(_)) {
                            if (links[_].name == link.parent) {
                                let i: any = {
                                    to: link.path,
                                    icon: link.meta.icon,
                                    name: link.name,
                                    weight: link.meta.weight,
                                    title: link.meta.title,
                                }
                                links[_].children.push(i)
                            }
                        }
                    }
                }
            }
            commit('LINKS', links);
        },
        toggleNavigation({commit}:any) {
            commit('TOGGLE_NAVIGATION');
        },
    }
}

modules['quick_user'] = {
    namespaced: true,
    state: {
        show: null,
    },
    mutations: {
        SHOW_QUICK_USER(state:any, show:any) {
            state.show = show
            // console.log('mutations.SHOW_INFO show', state.show)
        },
        TOGGLE_QUICK_USER(state:any) {
            state.show = !state.show
            // console.log('mutations.TOGGLE_INFO show', state.show)

        }
    },
    actions: {
        toggleQuickUser({commit}:any) {
            commit('TOGGLE_QUICK_USER');
        },
    }
}

modules['main'] = {
    namespaced: true,
    state: {
        registration: {},
        logo: {},
        integrations: [],
        selfRegistration: true,
        selfChangePersonalData: true
    },
    getters: {
        registration: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.registration
        },
        logo: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.logo
        },
        integrations: (state:any) => {
            return state.integrations;
        },
        selfRegistration: (state:any) => {
            return state.selfRegistration;
        },
        selfChangePersonalData: (state:any) => {
            return state.selfChangePersonalData;
        }
    },
    mutations: {
        INIT_REGISTRATION(state:any, registration:any) {
            state.registration = registration
        },
        INIT_LOGO(state:any, logo:any) {
            if (logo != null) {
              state.logo = logo;
            } else {
              state.logo = {};
            }
        },
        INIT_INTEGRATIONS(state:any, integrations:any) {
            integrations !== null ? state.integrations = integrations : integrations = []
        },
        INIT_SELF_REGISTRATION(state:any, registration:any) {
            state.selfRegistration = registration
        },
        INIT_SELF_CHANGE_PERSONAL_DATA(state:any, changePersonalData:any) {
            state.selfChangePersonalData = changePersonalData
        }
    },
    actions: {
        ClientInit: ({commit}:any) => {
            api.get('/v2/init/index', {}) // , {headers:{'language': 'en-US'}}  ???
                .then(responce => {
                    console.log('responce.data', responce.data)
                    console.log('responce.data[\'registration\']', responce.data['registration'])
                    if (responce.data['registration']) commit('INIT_REGISTRATION', responce.data['registration']);
                    if (responce.data['branding']) commit('INIT_LOGO', responce.data['branding'].logo);
                    if (responce.data['integrations']) commit('INIT_INTEGRATIONS', responce.data['integrations']);
                    if (responce.data) {
                        commit('INIT_SELF_REGISTRATION', responce.data.is_customer_self_registration_enabled);
                        commit('INIT_SELF_CHANGE_PERSONAL_DATA', responce.data.canChangePersonalData);
                    }
                }).catch(reason => {
                    console.log('reason', reason)
                })
        }
    }
}

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state: {
        notification: []
    },
    mutations: {},
    actions: {},
    modules: modules
})
