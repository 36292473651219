import api from '@/plugins/api'

export default {
    namespaced: true,
    state: {
        packages: [],
        orders: [],
        bookings: [],
    },
    getters: {
        packages: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.packages
        },
        orders: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.orders
        },
        bookings: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.bookings
        },
    },
    mutations: {
        INIT_PACKAGES(state:any, packages:any) {
            state.packages = packages
        },
        INIT_ORDERS(state:any, orders:any) {
            state.orders = orders
        },
        INIT_BOOKINGS(state:any, bookings:any) {
            state.bookings = bookings
        },
    },
    actions: {
        ClientInit ({commit, state}:{commit:any, state:any}, app:any) {

        },
        PackagesInit: ({commit}:any, app:any) => {
            let data = {expand: "rate,order", not_spent: false, sort: "id", state: "current"}
            // let data = {expand: "rate,order", not_spent: false, sort: "id"}
            api.get('/v2/packages', data)
                .then(responce => {
                    commit('INIT_PACKAGES', responce.data);
                }).catch(reason => {
                    console.error('reason', reason)
            })
        },
        OrdersInit: ({commit}:any, app:any) => {
            let data = {status_ids: 7}
            api.get('/v2/orders', data)
                .then(responce => {
                    commit('INIT_ORDERS', responce.data);
                }).catch(reason => {
                    console.error('reason', reason)
            })
        },
        BookingsInit: ({commit}:any, app:any) => {
            let data = {status: 1, expand: 'order,room,location,resource'}
            api.get('/v2/bookings', data)
                .then(responce => {
                    commit('INIT_BOOKINGS', responce.data);
                }).catch(reason => {
                    console.error('reason', reason)
            })
        }
    }
}

